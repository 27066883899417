import { config } from '../config';
import { authHeader } from '../helpers';
import axiosInstance from '../axiosInstance';

export const authService = {
  auth,
  login,
  register,
  registerAdvisor,
  verifyId,
  verifyOTP,
  resendOTP,
  // verifyUser,
  forgotPassword,
  updatePassword,
  sendClientVerificationOTP,
  verifyClientVerificationOTP,
  refreshToken
};

function auth() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return axiosInstance.get(`${config.auth_server}/`, requestOptions).then((response) => {
    return response.data;
  });
}

function login(credentials) {
  console.log('login');
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  };

  return axiosInstance
    .post(`${config.auth_server}/login`, credentials, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function register(credentials) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  };

  return axiosInstance
    .post(`${config.auth_server}/register`, credentials, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function registerAdvisor(credentials) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  };

  return axiosInstance
    .post(`${config.auth_server}/advisor/register`, credentials, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function forgotPassword(credentials) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  };

  return axiosInstance
    .post(`${config.auth_server}/forgot-password`, credentials, requestOptions)
    .then((response) => {
      console.log(response.data);
      return response.data;
    });
}

function updatePassword(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  };
  return axiosInstance
    .post(`${config.auth_server}/update-password`, data, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function verifyId(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .post(`${config.auth_server}/verifyId`, data, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function sendClientVerificationOTP(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .post(`${config.auth_server}/clientverificationotp/send`, data, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function verifyClientVerificationOTP(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .post(`${config.auth_server}/clientverificationotp/verify`, data, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function verifyOTP(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .post(`${config.auth_server}/verifyOTP`, data, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function resendOTP(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .post(`${config.auth_server}/resendOTP`, data, requestOptions)
    .then((response) => {
      return response.data;
    });
}

function refreshToken(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return axiosInstance
    .post(`${config.auth_server}/refresh-token`, {}, requestOptions)
    .then((response) => {
      return response.data;
    });
}
// function verifyUser(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json', ...authHeader() }
//   };

//   return axios.post(`${config.auth_server}/verifyUser`, data, requestOptions).then((response) => {
//     return response.data;
//   });
// }
