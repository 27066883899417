import { Box, Link, Typography, useTheme } from '@mui/material';

const Footer = () => {
  const theme = useTheme();
  const textColor = theme.palette.text.light;
  const accentColor = theme.palette.accent.main;

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'center'}
      alignItems={'center'}
      p={1}
      sx={{ backgroundColor: accentColor, color: textColor }}>
      <Typography variant="body2">
        © 2025 Fairtree. All rights reserved. |{' '}
        <Link
          href="https://fairtree.com/wp-content/uploads/2021/06/FAM-Privacy-Notice-07-06-2021.pdf"
          target="_blank"
          color={textColor}
          sx={{ cursor: 'pointer' }}>
          Privacy Notice
        </Link>{' '}
        |{' '}
        <Link
          href="https://fairtree.com/wp-content/uploads/2021/01/FAM-PAIA-Manual-202001.pdf"
          target="_blank"
          color={textColor}
          sx={{ cursor: 'pointer' }}>
          PAIA
        </Link>{' '}
        |{' '}
        <Link
          href="https://fairtree.com/disclaimer/"
          target="_blank"
          color={textColor}
          sx={{ cursor: 'pointer' }}>
          Disclaimer
        </Link>
      </Typography>
    </Box>
  );
};

export default Footer;
