import React, { useEffect, useState } from 'react';
import { AccessMap, UserFunctions, hasAccess } from '../helpers/access';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggestOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import Groups3OutlinedIcon from '@mui/icons-material/Groups3Outlined';
import BusinessIcon from '@mui/icons-material/BusinessOutlined';
import PersonIcon from '@mui/icons-material/Person';
import ArticleIcon from '@mui/icons-material/Article';
import HomeIcon from '@mui/icons-material/Home';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import DialpadOutlinedIcon from '@mui/icons-material/DialpadOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import ProfileIcon from '../resources/images/icons/light/Iconography_white_Individuals.png';
import BalanceImage from '../resources/images/icons/light/Iconography_white_Value.png';
import StartHereImage from '../resources/images/icons/light/Iconography_white_Growth.png';
import MyReportsImage from '../resources/images/icons/light/Iconography_white_Quantitive risk management.png';
import StatementsImage from '../resources/images/icons/light/Iconography_white_Endowment plan.png';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { AuthHelper } from '../helpers/authHelper';

export const generateNavItems = (user) => {
  const userIsConnected = !AuthHelper.userHasClientAccount(user);
  const clientReports = user.client.filter((client) => client.clientReports === true).length > 0;

  const navItems = [
    {
      title: 'Me',
      access: hasAccess(user, UserFunctions.GeneralAccess, AccessMap.Read),
      icon: <PersonIcon />,
      items: [
        {
          text: 'Balances',
          secondaryText: '',
          access: hasAccess(user, UserFunctions.GeneralAccess, AccessMap.Read),
          link: user.client && user.client.length > 1 ? '/select-client-account' : '/balances',
          icon: <FormatListBulletedIcon />,
          image: BalanceImage,
          hide: userIsConnected
        },
        {
          text: 'My Reports',
          secondaryText: '',
          access: hasAccess(user, UserFunctions.GeneralAccess, AccessMap.Read),
          link: '/clientreports',
          icon: <FormatListBulletedIcon />,
          image: MyReportsImage,
          hide: userIsConnected || !clientReports
        },
        {
          text: 'My Profile',
          secondaryText: '',
          access: hasAccess(user, UserFunctions.GeneralAccess, AccessMap.Read),
          link: '/user/profile',
          icon: <FormatListBulletedIcon />,
          image: ProfileIcon,
          hide: userIsConnected
        },
        {
          text: 'Start Here',
          secondaryText: '',
          access: hasAccess(user, UserFunctions.GeneralAccess, AccessMap.Read),
          link: '/starthere',
          icon: <FormatListBulletedIcon />,
          image: StartHereImage,
          hide: !userIsConnected
        }
      ]
    },
    {
      title: 'Advisor',
      access: hasAccess(user, UserFunctions.AdvisorAdmin, AccessMap.Read),
      icon: <NearMeOutlinedIcon />,
      items: [
        {
          text: 'My Clients',
          secondaryText: '',
          access: hasAccess(user, UserFunctions.AdvisorAdmin, AccessMap.Read),
          link: '/advisor/clients',
          icon: <FormatListBulletedIcon />
        },
        {
          text: 'My Codes',
          secondaryText: '',
          access: hasAccess(user, UserFunctions.AdvisorAdmin, AccessMap.Read),
          link: '/advisor/codes',
          icon: <DialpadOutlinedIcon />
        },
        {
          text: 'Submit a Support Ticket',
          secondaryText: '',
          access: hasAccess(user, UserFunctions.AdvisorAdmin, AccessMap.Read),
          link: '/advisor/submitTicket',
          icon: <LocalActivityOutlinedIcon />
        }
      ]
    },
    {
      title: 'Client Servicing',
      access: hasAccess(user, UserFunctions.ClientsAdmin, AccessMap.Read),
      icon: <SupportAgentIcon />,
      items: [
        {
          text: 'Client List',
          secondaryText: '',
          access: hasAccess(user, UserFunctions.ClientsAdmin, AccessMap.Read),
          link: '/admin/clients',
          icon: <FormatListBulletedIcon />
        },
        {
          text: 'Guidance Tool',
          secondaryText: '',
          access: hasAccess(user, UserFunctions.ClientsAdmin, AccessMap.Read),
          link: '/admin/fundfilter',
          icon: <FilterAltIcon />
        }
      ],
      hide: false
    },
    {
      title: 'System Admin',
      access: hasAccess(user, UserFunctions.SystemAdmin, AccessMap.Read),
      icon: <SettingsSuggestIcon />,
      items: [
        {
          text: 'Service Providers',
          secondaryText: '',
          access: hasAccess(user, UserFunctions.ServiceProviders, AccessMap.Read),
          link: '/admin/serviceproviders',
          icon: <AccountBalanceIcon />
        },
        {
          text: 'Actions',
          secondaryText: '',
          access: hasAccess(user, UserFunctions.ApplicaitonSettings, AccessMap.Read),
          link: '/admin/actions',
          icon: <SettingsIcon />
        },
        {
          text: 'Users',
          secondaryText: '',
          access: hasAccess(user, UserFunctions.Users, AccessMap.Read),
          link: '/admin/users',
          icon: <PeopleIcon />
        },
        {
          text: 'Roles',
          secondaryText: '',
          access: hasAccess(user, UserFunctions.Roles, AccessMap.Read),
          link: '/admin/roles',
          icon: <Groups3OutlinedIcon />
        },
        {
          text: 'Entities',
          secondaryText: '',
          access: hasAccess(user, UserFunctions.Entities, AccessMap.Read),
          link: '/admin/entities',
          icon: <BusinessIcon />
        }
      ],
      hide: false
    },
    {
      title: 'Funds',
      access: hasAccess(user, UserFunctions.SystemAdmin, AccessMap.Read),
      icon: <PaidOutlinedIcon />,
      items: [
        {
          text: 'Transaction Types',
          secondaryText: '',
          access: hasAccess(user, UserFunctions.TransactionTypes, AccessMap.Read),
          link: '/admin/settings/transactiontypes',
          icon: <AccountTreeIcon />
        },
        {
          text: 'Models',
          secondaryText: '',
          access: hasAccess(user, UserFunctions.Models, AccessMap.Read),
          link: '/admin/settings/housemodels',
          icon: <AccountBalanceWalletIcon />
        },
        {
          text: 'Reports',
          secondaryText: '',
          access: hasAccess(user, UserFunctions.Models, AccessMap.Read),
          link: '/admin/settings/reports',
          icon: <ArticleIcon />
        },
        {
          text: 'Products',
          secondaryText: '',
          access: hasAccess(user, UserFunctions.Products, AccessMap.Read),
          link: '/admin/settings/products',
          icon: <HomeIcon />
        },
        {
          text: 'Funds',
          secondaryText: '',
          access: hasAccess(user, UserFunctions.Funds, AccessMap.Read),
          link: '/admin/funds',
          icon: <AccountBalanceWalletIcon />
        },
        {
          text: 'MDDs',
          secondaryText: '',
          access: hasAccess(user, UserFunctions.Funds, AccessMap.Read),
          link: '/admin/funds/mdds',
          icon: <PdfIcon />
        }
      ],
      hide: false
    }
  ];

  return navItems;
};
