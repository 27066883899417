import { Badge, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import NotificationCard from './notificationCard';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNotifications,
  getUnseenCount,
  markNotificationAsSeen
} from '../../../features/notification/notification.slice';

import NotificationsIcon from '@mui/icons-material/Notifications';

const NotificationsList = (open, onClose) => {
  const dispatch = useDispatch();
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);

  const notifications = useSelector((state) => state.notification.notifications || []);
  const unseenCount = useSelector((state) => state.notification.unseenCount);
  const userId = useSelector((state) => state.auth.user._id);

  useEffect(() => {
    dispatch(getNotifications()).unwrap().then(dispatch(getUnseenCount()));
  }, [dispatch]);

  const handleOpenNotifications = (event) => {
    const hasUnseenNotifications = notifications.some((notification) => !notification.seen);
    if (hasUnseenNotifications) {
      markAsSeen();
    }

    setAnchorElNotifications(event.currentTarget);
  };

  const handleCloseNotifications = () => {
    setAnchorElNotifications(null);
  };

  const markAsSeen = () => {
    notifications
      .filter((notification) => !notification.seen)
      .forEach((notification) => {
        dispatch(
          markNotificationAsSeen({ notificationId: notification._id, userId: userId, seen: true })
        )
          .unwrap()
          .then(dispatch(getUnseenCount()));
      });
  };

  return (
    <>
      <IconButton onClick={(event) => handleOpenNotifications(event)} sx={{ p: 1 }}>
        <Badge badgeContent={unseenCount}>
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Menu
        sx={{ mt: '45px' }}
        slotProps={{ paper: { sx: { boxShadow: 'inherit' } } }}
        id="notifications-menu"
        anchorEl={anchorElNotifications}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElNotifications)}
        onClose={handleCloseNotifications}>
        {notifications && notifications.length === 0 ? (
          <MenuItem>
            <Typography textAlign="center">No notifications</Typography>
          </MenuItem>
        ) : (
          notifications.map((notification, index) => (
            <NotificationCard notification={notification} key={index} />
          ))
        )}
      </Menu>
    </>
  );
};

export default NotificationsList;
