import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getServiceProviders } from '../../features/serviceProvider/serviceProvider.slice';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DataGrid, renderActionsCell } from '@mui/x-data-grid';
import { Paper, Box, MenuItem, Typography } from '@mui/material';

import Select from '../ui/forms/fields/select';
import Input from '../ui/forms/fields/input';
import PrimaryButton from '../ui/buttons/primaryButton';
import { getStatementsFilter } from '../../features/account/account.slice';
import moment from 'moment';
import { fileStorageService } from '../../features/fileStorage/fileStorage.service';
import { scheduleStatementNotifications } from '../../features/notification/notification.slice';

const filterSchema = yup.object().shape({
  serviceProvider: yup.string().required('Service Provider is required.'),
  month: yup.string().required('Please select a month.'),
  accountNumber: yup.string()
});

const statementGridColumns = [
  {
    field: 'fileName',
    headerName: 'File Name',
    width: 350
  },
  {
    field: 'accountNumber',
    headerName: 'Account Number',
    width: 200
  },
  {
    field: 'fileDate',
    headerName: 'Date',
    valueGetter: (value, row) => {
      return `${moment(value.value).format('DD/MM/YYYY')}`;
    },
    width: 200
  },
  {
    field: 'actions',
    headerName: 'Actions',
    renderCell: (value) => {
      return (
        <PrimaryButton onClick={() => handleDownload(value.row._id, value.row.fileName)}>
          Download
        </PrimaryButton>
      );
    },
    width: 200
  }
];

const handleDownload = (id, fileName) => {
  fileStorageService.downloadFile(id, fileName);
};

const StatementActions = () => {
  const dispatch = useDispatch();
  const serviceProviders = useSelector((state) => state.serviceProvider?.providers);
  const statements = useSelector((state) => state.account.statements);
  const schedulingStatements = useSelector((state) => state.notification.schedulingStatements);

  const [selectedStatements, setSelectedStatements] = React.useState([]);

  const statementsGridRows = statements;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(filterSchema),
    mode: 'onBlur'
  });

  useEffect(() => {
    dispatch(getServiceProviders());
  }, [dispatch]);

  const onSubmit = (data) => {
    console.log(data);

    dispatch(getStatementsFilter(data));
  };

  const handleStatementsSend = () => {
    //Schedule for send now.
    dispatch(scheduleStatementNotifications({ selectedStatements, dateTime: moment() }));

    console.log(selectedStatements);
  };

  return (
    <Fragment>
      <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="h3">Filter Statements</Typography>
        <Box display={'flex'} gap={3} sx={{ py: 2 }} justifyContent={'space-between'}>
          <Select
            control={control}
            name="serviceProvider"
            label="Service Provider"
            error={!!errors.serviceProvider}
            helperText={errors?.serviceProvider?.message}>
            {serviceProviders &&
              serviceProviders.map(
                (sp, index) =>
                  sp.hasStatements &&
                  sp.mode === 'active' && (
                    <MenuItem key={sp._id} value={sp._id}>
                      {sp.name}
                    </MenuItem>
                  )
              )}
          </Select>
          <Select
            control={control}
            name="month"
            label={'Month'}
            error={!!errors.month}
            helperText={errors?.month?.message}>
            {Array.from({ length: 12 }, (v, k) => k)
              .map((i) => moment().subtract(i, 'months'))
              .map((date) => (
                <MenuItem key={date.format('MMMM YYYY')} value={date.format('MMMM YYYY')}>
                  {date.format('MMMM YYYY')}
                </MenuItem>
              ))}
          </Select>

          <Input
            label="Account Number"
            helperText={errors?.accountNumber?.message}
            error={!!errors.accountNumber}
            {...register}
          />
        </Box>
        <PrimaryButton onClick={() => handleSubmit(onSubmit)()}>Find</PrimaryButton>
      </Paper>
      <Paper sx={{ mb: 3 }}>
        {schedulingStatements ? (
          <Box display="flex" justifyContent={'flex-end'} gap={2} p={2}>
            <Typography>Statements are being scheduled...</Typography>
          </Box>
        ) : (
          <Box display="flex" justifyContent={'flex-end'} gap={2} p={2}>
            {selectedStatements && selectedStatements.length > 0 ? (
              <PrimaryButton onClick={() => handleStatementsSend()}>
                Send Statements Now
              </PrimaryButton>
            ) : null}
          </Box>
        )}

        <Box sx={{ height: 400, width: '100%' }}>
          {statements && (
            <DataGrid
              getRowId={(row) => row._id}
              rows={statementsGridRows}
              columns={statementGridColumns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5
                  }
                }
              }}
              pageSizeOptions={[5]}
              checkboxSelection
              disableRowSelectionOnClick
              onSelectionModelChange={(data) => {
                setSelectedStatements(data);
              }}
            />
          )}
        </Box>

        {schedulingStatements ? (
          <Box display="flex" justifyContent={'flex-end'} gap={2} p={2}>
            <Typography>Statements are being scheduled...</Typography>
          </Box>
        ) : (
          <Box display="flex" justifyContent={'flex-end'} gap={2} p={2}>
            {selectedStatements && selectedStatements.length > 0 ? (
              <PrimaryButton onClick={() => handleStatementsSend()}>
                Send Statements Now
              </PrimaryButton>
            ) : null}
          </Box>
        )}
      </Paper>
    </Fragment>
  );
};

export default StatementActions;
