import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Link } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Form from '../ui/forms';

import { Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login, logout } from '../../features/auth/auth.slice';

import Input from '../ui/forms/fields/input';
import PrimaryButton from '../ui/buttons/primaryButton';

import ReCAPTCHA from 'react-google-recaptcha';

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is required.')
    .email('This does not look like a email address.'),
  password: yup.string().required('Password is required.')
});

function SignIn() {
  const recaptchaRef = React.useRef();
  const [captchaError, setCaptchaError] = React.useState(null);
  const dispatch = useDispatch();
  const theme = useTheme();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);
  const onSubmit = async (data) => {
    const token = await recaptchaRef.current.executeAsync();

    if (!token) {
      setCaptchaError('Please complete the reCAPTCHA');
      return;
    }

    data.recaptchaToken = token;
    setCaptchaError(null);
    await dispatch(login(data));

    recaptchaRef.current.reset();
  };

  return (
    <Box display={'flex'} flexDirection={'row'} width={'100%'} justifyContent={'center'}>
      <Box
        display={'flex'}
        sx={{
          backgroundColor: theme.palette.background.backdrop,
          borderRadius: 10,
          minWidth: '30vw'
        }}
        p={3}
        m={3}
        justifyContent={'center'}
        alignItems={'center'}>
        <Box
          sx={{
            maxWidth: '250px',
            height: '50%'
          }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems={'center'}>
          <Typography variant="h2" gutterBottom>
            Login
          </Typography>

          <Form onSubmit={handleSubmit(onSubmit)} sx={{ textAlign: 'center' }}>
            <Input
              id="email"
              label="Email Address"
              type="email"
              {...register('email')}
              autoComplete="email"
              error={!!errors.email}
              helperText={errors?.email?.message}
            />
            <Input
              id="password"
              {...register('password')}
              label="Password"
              type="password"
              autoComplete="current-password"
              error={!!errors.password}
              helperText={errors?.password?.message}
            />
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onErrored={(err) => {
                setCaptchaError('reCAPTCHA error occurred. Please try again.');
              }}
            />
            {captchaError && (
              <Typography variant="p" color="error">
                {captchaError}
              </Typography>
            )}
          </Form>
          <PrimaryButton onClick={handleSubmit(onSubmit)}>Login</PrimaryButton>
          <Typography>
            Not Registered? <Link href="/signup/select">{'Sign Up'}</Link>
          </Typography>
          <Divider
            sx={{
              borderBottomWidth: 4,
              borderColor: '#000',
              width: '80px',
              mt: '80px',
              mb: '30px'
            }}
          />
          <Typography variant="p">
            {'If you forgot your password click '}
            <Link href="/forgot-password">{'here'}</Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default SignIn;
